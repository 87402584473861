import MaterialDetail from '../domain/MaterialDetail'

type Props = {
  materialDetail: MaterialDetail
  onChangeMaterialDetail: (key: string, value: string) => void
}

const MaterialDetailsSection = ({ materialDetail, onChangeMaterialDetail }: Props) => {
  return (
    <>
      <div className="content">
        {materialDetail.contentIdType !== 'ArticleId' && (
          <div className="item">
            <div className="label">{materialDetail.contentIdType}</div>
            <input
              disabled={true}
              type="text"
              onChange={(e) => onChangeMaterialDetail('contentId', e.target.value)}
              aria-label="contentId"
              value={materialDetail.contentId}
            />
          </div>
        )}
        <div className="item">
          <div className="label">제목</div>
          <input
            type="text"
            onChange={(e) => onChangeMaterialDetail('title', e.target.value)}
            aria-label="title"
            value={materialDetail.title}
          />
        </div>
        <div className="items">
          <div className="item">
            <div className="label">발행연도</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('publishYear', e.target.value)}
              aria-label="publishYear"
              value={materialDetail.publishYear}
            />
          </div>
          <div className="item">
            <div className="label">기관명</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('publisher', e.target.value)}
              aria-label="publisher"
              value={materialDetail.publisher}
            />
          </div>
        </div>
        <div className="items">
          <div className="item">
            <div className="label">학술지명</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('journal', e.target.value)}
              aria-label="journal"
              value={materialDetail.journal}
            />
          </div>
          <div className="item">
            <div className="label">유형</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('journalType', e.target.value)}
              aria-label="journalType"
              value={materialDetail.journalType}
            />
          </div>
        </div>
        <div className="item">
          <div className="label">저자</div>
          <input
            type="text"
            onChange={(e) => onChangeMaterialDetail('authors', e.target.value)}
            aria-label="authors"
            value={materialDetail.authors}
          />
        </div>
        <div className="item">
          <div className="label">초록</div>
          <textarea
            onChange={(e) => onChangeMaterialDetail('abstractsContent', e.target.value)}
            aria-label="abstractsContent"
            value={materialDetail.abstractsContent}
          />
        </div>
        <div className="memo-section">
          <div className="item">
            <div className="label">메모 제목</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('memoTitle', e.target.value)}
              aria-label="memoTitle"
              value={materialDetail.memoTitle}
            />
          </div>
          <div className="item">
            <div className="label">메모 내용</div>
            <textarea
              onChange={(e) => onChangeMaterialDetail('memoContent', e.target.value)}
              aria-label="memoContent"
              value={materialDetail.memoContent}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MaterialDetailsSection
