import { useCallback, useState } from 'react'
import { RightSection } from './TopicTreeManagement'

type Props = {
  onCreateTopic: (topicName: string) => void
  setCurrentRightSection: (rightSection: RightSection) => void
}

const CreateTopicSection = ({ onCreateTopic, setCurrentRightSection }: Props) => {
  const [topicName, setTopicName] = useState<string>('')

  const onCreateTopicHandler = useCallback(() => {
    if (!topicName) {
      return
    }
    onCreateTopic(topicName)
    setTopicName('')
  }, [topicName, onCreateTopic])

  return (
    <div className="create-topic-section">
      <div className="create-form-wrap">
        <div className="title">새 토픽 만들기</div>
        <div className="create-form">
          <div className="label">토픽 이름</div>
          <div className="text-box-wrap">
            <input
              value={topicName}
              onChange={(event) => setTopicName(event.target.value)}
              aria-label="topic-name"
            />
            <button onClick={onCreateTopicHandler}>생성</button>
          </div>
        </div>
      </div>
      <div className="or">또는</div>
      <div className="creation-options">
        <div className="creation-option" aria-label="existed-topic">
          <div className="icon tree" />
          <div className="option-name">
            <div>기존 토픽트리에서</div>
            <div>불러오기</div>
          </div>
          <div className="option-description">
            <div>기존 토픽트리 중</div>
            <div>원하는 토픽을 불러옵니다.</div>
          </div>
          <button onClick={() => setCurrentRightSection('createByExistedTopic')}>선택</button>
        </div>
        <div className="creation-option" aria-label="specific-document">
          <div className="icon document" />
          <div className="option-name">
            <div>특정 문서에서</div>
            <div>추출하기</div>
          </div>
          <div className="option-description">
            <div>특정 문서를 기반으로</div>
            <div>토픽을 생성합니다.</div>
          </div>
          <button onClick={() => setCurrentRightSection('createByDocument')}>선택</button>
        </div>
        <div className="creation-option" aria-label="topic-map">
          <div className="icon database" />
          <div className="option-name">
            <div>토픽맵 DB에서</div>
            <div>검색하기</div>
          </div>
          <div className="option-description">
            <div>토픽맵 DB에서 키워드를</div>
            <div>검색하여 토픽을 만듭니다.</div>
          </div>
          <button onClick={() => setCurrentRightSection('createByTopicMap')}>선택</button>
        </div>
      </div>
    </div>
  )
}

export default CreateTopicSection
