import React from 'react'

type NavigateCallBack = () => void

interface Props {
  paths: string[]
  navigates?: { [key: string]: NavigateCallBack }
}

const Location = ({ paths, navigates = {} }: Props) => {
  return (
    <ol className="location">
      <li>
        <span className="icon-home">Home</span>
      </li>
      {paths.map((path, index) => (
        <li key={`${path}-${index}`} onClick={navigates[path] ? navigates[path] : () => {}}>
          {index !== paths.length - 1 ? <span>{path}</span> : <strong>{path ? path : '-'}</strong>}
        </li>
      ))}
    </ol>
  )
}

export default Location
