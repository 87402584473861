import React, { MouseEvent, useCallback, useContext, useEffect, useState } from 'react'

import AllCheckBox from '../../common/component/AllCheckBox'
import CheckBox from '../../common/component/CheckBox'
import ModalWindow from '../../common/component/ModalWindow'
import Pagination from '../../common/component/Pagination'
import { usePageable } from '../../common/hooks/usePageable'
import { useCheckbox } from '../../common/hooks/useCheckbox'

import TactoProjectDetail from '../domain/TactoProjectDetail'
import TopicTreeNode from '../domain/TopicTreeNode'
import Material from '../domain/Material'

import { updatedDate } from '../util/time-formatter'
import {
  deleteMarkingMaterials,
  getMaterialDetailsByIds,
  getMaterials,
} from '../repository/MaterialsRepository'

import DeleteRestoreTactoItem from './DeleteRestoreTactoItem'
import MoveTopicMaterial from './MoveTopicMaterial'
import TopicMaterialSearch from './TopicMaterialSearch'
import SearchInMyStorage from './SearchInMyStorage'
import { Context } from '../provider/TactoProjectProvider'
import AddExternalContent from './AddExternalContent'
import { exporterFactory, ExportType } from '../../article/utils/ArticleExporter'
import MaterialDetail from '../domain/MaterialDetail'
import Article from '../../article/domain/Article'
import ArticleAuthor from '../../article/domain/ArticleAuthor'

type Props = {
  projectDetail: TactoProjectDetail
  fetchProjectDetail: () => void
  selectedTopic: TopicTreeNode | null
  openMaterialDetail: (material: Material) => void
  openAttachment: (e: MouseEvent<HTMLButtonElement>, material: Material) => void
  openMemo: (e: MouseEvent<HTMLButtonElement>, material: Material) => void
  collectModalOpen: boolean
  setCollectModalOpen: (value: boolean) => void
  isRoot: boolean
}

const convertMaterialToArticle = (material: MaterialDetail): Article => {
  return {
    id: material.id,
    articleType: '',
    title: material.title,
    subTitle: '',
    entireTitle: material.title,
    keywords: [],
    subKeywords: [],
    abstractsTitle: '',
    abstractsContent: material.abstractsContent,
    subAbstractsTitle: '',
    subAbstractsContent: '',
    entireAbstracts: material.abstractsContent,
    categoryNames: [],
    authors: [new ArticleAuthor(0, material.authors, '', 0)],
    articleUrl: '',
    doi: material.contentId,
    articleSource: {
      contentId: '',
      journalId: 0,
      journal: material.journal,
      publisher: material.publisher,
      publishYear: material.publishYear,
      volumeIssue: '',
      startPage: 0,
      endPage: 0,
      totalPage: 0,
      serialInfo: '',
    },
    otherUrls: [],
    patentClaims: [],
  }
}

const MaterialsSection = ({
  projectDetail,
  fetchProjectDetail,
  selectedTopic,
  openMaterialDetail,
  openAttachment,
  openMemo,
  collectModalOpen,
  setCollectModalOpen,
  isRoot,
}: Props) => {
  const { registerRefreshItems } = useContext(Context)!
  const [copyMode, setCopyMode] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [materials, setMaterials] = useState<Material[]>([])
  const [openAddOptions, setOpenAddOptions] = useState<boolean>(false)
  const [openExportOptions, setOpenExportOptions] = useState<boolean>(false)
  const [addExternalContentModalOpen, setAddExternalContentModalOpen] = useState<boolean>(false)
  const [searchInMyStorageModalOpen, setSearchInMyStorageModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false)
  const { pageInfo, setupPage } = usePageable()
  const { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked } =
    useCheckbox()

  const moveToPage = useCallback(
    (currentPage: number) => {
      if (selectedTopic) {
        clearAllChecked()
        getMaterials(selectedTopic.id, keyword, isRoot, { page: currentPage, size: 10 }).then(
          (response) => {
            setupPage(response, currentPage)
            setMaterials(response.content)
          }
        )
      }
    },
    [selectedTopic, clearAllChecked, keyword, isRoot, setupPage]
  )

  const onSearchHandler = useCallback(() => moveToPage(0), [moveToPage])

  const openDeleteModal = () => {
    if (checkedItems.size === 0) {
      alert('선택한 자료가 없습니다.')
      return
    }
    setDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
    fetchProjectDetail()
  }

  const openMove = (isCopy: boolean) => {
    if (checkedItems.size === 0) {
      alert('선택한 자료가 없습니다.')
      return
    }
    setMoveModalOpen(true)
    setCopyMode(isCopy)
  }

  const openSearchInMyStorage = useCallback(() => {
    setSearchInMyStorageModalOpen(true)
    setOpenAddOptions(false)
  }, [])

  const openAddExternalContent = useCallback(() => {
    setAddExternalContentModalOpen(true)
    setOpenAddOptions(false)
  }, [])

  const onExportMaterialHandler = (exportType: ExportType) => {
    if (checkedItems.size === 0) {
      alert('선택한 자료가 없습니다.')
      return
    }
    const exporter = exporterFactory(exportType)
    getMaterialDetailsByIds(Array.from(checkedItems))
      .then((materials) => {
        const articles = materials.map(convertMaterialToArticle)
        exporter.exportArticle(articles)
      })
      .finally(() => {
        clearAllChecked()
        setOpenExportOptions(false)
      })
  }

  useEffect(() => {
    moveToPage(0)
    registerRefreshItems(() => moveToPage(0))
    // eslint-disable-next-line
  }, [selectedTopic])

  return (
    <>
      <ModalWindow
        isOpen={addExternalContentModalOpen}
        closeModal={() => setAddExternalContentModalOpen(false)}
      >
        <AddExternalContent
          projectId={projectDetail.id}
          topicId={selectedTopic?.id || ''}
          fetchMaterials={() => moveToPage(0)}
          closeModal={() => {
            setAddExternalContentModalOpen(false)
            fetchProjectDetail()
          }}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={searchInMyStorageModalOpen}
        closeModal={() => setSearchInMyStorageModalOpen(false)}
      >
        <SearchInMyStorage
          projectId={projectDetail.id}
          topicId={selectedTopic?.id || ''}
          fetchMaterials={() => moveToPage(0)}
          closeModal={() => {
            setSearchInMyStorageModalOpen(false)
            fetchProjectDetail()
          }}
        />
      </ModalWindow>
      <ModalWindow isOpen={collectModalOpen} closeModal={() => setCollectModalOpen(false)}>
        <TopicMaterialSearch
          projectId={projectDetail.id}
          topicNode={selectedTopic}
          fetchMaterials={() => moveToPage(0)}
          closeModal={() => {
            setCollectModalOpen(false)
            fetchProjectDetail()
          }}
        />
      </ModalWindow>
      {projectDetail && projectDetail.rootNode && (
        <ModalWindow isOpen={moveModalOpen} closeModal={() => setMoveModalOpen(false)}>
          <MoveTopicMaterial
            rootNode={projectDetail.rootNode}
            ids={checkedItems}
            fetchMaterials={() => moveToPage(0)}
            closeModal={() => {
              setMoveModalOpen(false)
              fetchProjectDetail()
            }}
            copyMode={copyMode}
          />
        </ModalWindow>
      )}
      <ModalWindow isOpen={deleteModalOpen} closeModal={() => setDeleteModalOpen(false)}>
        <DeleteRestoreTactoItem
          title="자료 삭제"
          message="선택한 자료를 삭제합니다."
          isDeleteMode={true}
          confirmCallback={() => {
            deleteMarkingMaterials(Array.from(checkedItems))
              .then(() => moveToPage(0))
              .finally(closeDeleteModal)
          }}
          closeModal={closeDeleteModal}
        />
      </ModalWindow>
      <div className="tacto-project" aria-label="materialsSection">
        <div className="topic-info-section">
          <div className="title">{selectedTopic?.name}</div>
          <div className="description">{selectedTopic?.description}</div>
        </div>
        <div className="topic-top-section">
          <div className="buttons">
            <AllCheckBox
              isAllChecked={isAllChecked}
              onAllCheckHandler={(e) => onAllCheckHandler(e, materials)}
            />
            {!isRoot && (
              <div className="options-container">
                <div className="button" onClick={() => setOpenAddOptions(!openAddOptions)}>
                  <div className="icon topic-add-button" />
                  <button>추가</button>
                </div>
                {openAddOptions && (
                  <div className="button-options" aria-label="add-options">
                    <div onClick={openAddExternalContent}>문서 정보로 자료 추가</div>
                    <div onClick={openSearchInMyStorage}>내 서재에서 가져오기</div>
                  </div>
                )}
              </div>
            )}
            {isRoot && (
              <div className="button" onClick={() => openMove(true)}>
                <div className="icon topic-copy-button" />
                <button>복사</button>
              </div>
            )}
            <div className="button" onClick={() => openMove(false)}>
              <div className="icon topic-move-button" />
              <button>이동</button>
            </div>
            <div className="options-container">
              <div className="button" onClick={() => setOpenExportOptions(!openExportOptions)}>
                <div className="icon topic-export-button"></div>
                <button>내보내기</button>
              </div>
              {openExportOptions && (
                <div className="button-options" aria-label="export-options">
                  <div onClick={() => onExportMaterialHandler('Endnote')}>Endnote</div>
                  <div onClick={() => onExportMaterialHandler('RefWorks')}>RefWorks</div>
                  <div onClick={() => onExportMaterialHandler('BibTex')}>BibTex</div>
                  <div onClick={() => onExportMaterialHandler('Excel')}>Excel</div>
                </div>
              )}
            </div>
            <div className="button" onClick={openDeleteModal}>
              <div className="icon topic-delete-button" />
              <button>삭제</button>
            </div>
          </div>
          <div className="search">
            <input
              type="text"
              placeholder="검색어를 입력해주세요"
              onChange={(e) => setKeyword(e.target.value)}
            />
            <button aria-label="search" onClick={onSearchHandler} />
          </div>
        </div>
        <div className="project-item-list">
          {materials.map((material) => (
            <div key={material.id} className="project-item-wrap">
              <CheckBox
                onChange={(e) => onCheckedHandler(e, material.id)}
                className="sgl-check unit"
                ariaLabel={`checkbox-${material.id}`}
                isAllChecked={isAllChecked}
                checked={checkedItems.has(material.id)}
              />
              <div className="material" onClick={() => openMaterialDetail(material)}>
                {isRoot && <div className="topic-name">{material.topicName}</div>}
                <div className="title">{material.title}</div>
                <div className="content">
                  <div className="left-container">
                    <div>{material.publishYear}</div>
                    <div className="divider" />
                    <div>{material.publisher}</div>
                    <div className="divider" />
                    <div>{material.authors}</div>
                  </div>
                  <div className="attachment-memo-container">
                    <button
                      onClick={(e) => openAttachment(e, material)}
                      className={material.attachmentId ? 'existed' : ''}
                    >
                      <div className="icon attachment" />
                      첨부
                    </button>
                    <button
                      onClick={(e) => openMemo(e, material)}
                      className={material.memoId ? 'existed' : ''}
                    >
                      <div className="icon memo" />
                      메모
                    </button>
                    <div className="updated-at">{updatedDate(material.updatedAt)}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination moveToPage={moveToPage} pageInfo={pageInfo} />
    </>
  )
}

export default MaterialsSection
