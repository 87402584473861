import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import ModalWindow from '../../common/component/ModalWindow'
import DeleteRestoreTactoItem from './DeleteRestoreTactoItem'

import WorkspaceTactoProject from '../domain/WorkspaceTactoProject'

import { updatedDate } from '../util/time-formatter'
import { deleteProject, restoreProject } from '../repository/TactoWorkspaceRepository'

type Props = {
  projects: WorkspaceTactoProject[]
  isInTrash: boolean
  refreshItems: () => void
}

const TactoProjectList = ({ projects, isInTrash, refreshItems }: Props) => {
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = React.useState<WorkspaceTactoProject | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false)
  const [restoreModalOpen, setRestoreModalOpen] = React.useState<boolean>(false)

  const moveToProjectPage = useCallback(
    (id: number) => {
      if (!isInTrash) {
        navigate(`/knotes/projects/${id}`)
      }
    },
    [isInTrash, navigate]
  )

  return (
    <>
      <ModalWindow isOpen={restoreModalOpen} closeModal={() => setRestoreModalOpen(false)}>
        <DeleteRestoreTactoItem
          title="프로젝트 복원"
          message="복원하시겠습니까?"
          isDeleteMode={false}
          confirmCallback={() => {
            if (selectedItem) {
              restoreProject(selectedItem.id).then(refreshItems)
            }
          }}
          closeModal={() => setRestoreModalOpen(false)}
        />
      </ModalWindow>
      <ModalWindow isOpen={deleteModalOpen} closeModal={() => setDeleteModalOpen(false)}>
        <DeleteRestoreTactoItem
          title="프로젝트 삭제"
          message="삭제하시겠습니까?"
          isDeleteMode={true}
          confirmCallback={() => {
            if (selectedItem) {
              deleteProject(selectedItem.id).then(refreshItems)
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
        />
      </ModalWindow>
      <div className="workspace-items" aria-label="project-list">
        {projects.map((item) => (
          <div className="workspace-item-wrap" key={item.id}>
            <div className="workspace-item" onClick={() => moveToProjectPage(item.id)}>
              <div className="name">{item.name}</div>
              <div className="description">{item.description}</div>
              <div className="info-wrap">
                <div className="etc-info">
                  <div className="topic-info">
                    <div>{item.topicSummary.mainTopic}</div>
                    <div>{item.topicSummary.topicCount}개의 토픽</div>
                  </div>
                  <div className="count-info">
                    <div>
                      메모 <span>{item.memoCount}</span>
                    </div>
                    <div className="divider" />
                    <div>
                      아티클 <span>{item.tactoArticleCount}</span>
                    </div>
                    <div className="divider" />
                    <div>
                      자료 <span>{item.materialCount}</span>
                    </div>
                  </div>
                </div>
                <div className="updated-at">
                  {updatedDate(item.updatedAt, isInTrash ? '삭제' : '수정')}
                </div>
              </div>
            </div>
            {isInTrash && (
              <div className="trash-buttons">
                <button
                  className="restore"
                  onClick={(e) => {
                    e.preventDefault()
                    setRestoreModalOpen(true)
                    setSelectedItem(item)
                  }}
                >
                  복원
                </button>
                <button
                  className="delete"
                  onClick={(e) => {
                    e.preventDefault()
                    setDeleteModalOpen(true)
                    setSelectedItem(item)
                  }}
                  aria-label="delete"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default TactoProjectList
