import { useCallback, useMemo, useState } from 'react'
import DocumentOption from '../domain/DocumentOption'
import { getExternalContent } from '../repository/ExternalContentRepository'
import MaterialDetail from '../domain/MaterialDetail'
import MaterialDetailsSection from './MaterialDetailsSection'
import { addMaterialDetail } from '../repository/MaterialsRepository'

type Props = {
  projectId: number
  topicId: string
  closeModal: () => void
  fetchMaterials: () => void
}

const AddExternalContent = ({ projectId, topicId, closeModal, fetchMaterials }: Props) => {
  const [id, setId] = useState<string>('')
  const [materialDetail, setMaterialDetail] = useState<MaterialDetail | null>(null)
  const [documentOption, setDocumentOption] = useState<DocumentOption>('DOI')
  const optionTitle = useMemo(() => {
    switch (documentOption) {
      case 'ApplicationNo':
        return '출원번호'
      default:
        return documentOption
    }
  }, [documentOption])

  const OptionInput = useMemo(() => {
    switch (documentOption) {
      case '':
        return <></>
      default:
        return (
          <input
            type="text"
            aria-label="text-source"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        )
    }
  }, [documentOption, id])

  const onConfirm = () => {
    getExternalContent(documentOption, id).then((response) => setMaterialDetail(response))
  }

  const onChangeMaterialDetail = useCallback(
    (key: string, value: string) => {
      if (materialDetail) {
        setMaterialDetail({ ...materialDetail, [key]: value })
      }
    },
    [materialDetail]
  )

  return (
    <div className="add-external-content" aria-label="add-external-content">
      <div className="title">문서 정보로 자료 추가</div>
      {materialDetail === null ? (
        <div className="add-container">
          <div className="document-options" aria-label="source-categories">
            <div
              className={`document-option ${documentOption === 'DOI' && 'on'}`}
              onClick={() => setDocumentOption('DOI')}
            >
              DOI
            </div>
            <div
              className={`document-option ${documentOption === 'PMID' && 'on'}`}
              onClick={() => setDocumentOption('PMID')}
            >
              PMID
            </div>
            <div
              className={`document-option ${documentOption === 'PMCID' && 'on'}`}
              onClick={() => setDocumentOption('PMCID')}
            >
              PMCID
            </div>
            <div
              className={`document-option ${documentOption === 'ApplicationNo' && 'on'}`}
              onClick={() => setDocumentOption('ApplicationNo')}
            >
              출원번호
            </div>
            <div
              className={`document-option ${documentOption === 'URL' && 'on'}`}
              onClick={() => setDocumentOption('URL')}
            >
              URL
            </div>
          </div>
          <div className="option-description">
            위 항목 중 하나를 선택하여 입력, 또는 파일을 첨부해주세요.
          </div>
          <div className="source-form" aria-label="source-form">
            <div className="source-label">{optionTitle}</div>
            <div className="source-input">{OptionInput}</div>
          </div>
          <div className="buttons">
            <button className="cancel" onClick={closeModal}>
              취소
            </button>
            <button className="confirm" onClick={onConfirm}>
              확인
            </button>
          </div>
        </div>
      ) : (
        <>
          <div aria-label="material-details-section">
            <MaterialDetailsSection
              materialDetail={materialDetail}
              onChangeMaterialDetail={onChangeMaterialDetail}
            />
          </div>
          <div className="buttons">
            <button className="cancel" onClick={closeModal}>
              취소
            </button>
            <button
              className="confirm"
              onClick={() => {
                addMaterialDetail(projectId, topicId, materialDetail)
                  .then(() => {
                    fetchMaterials()
                    closeModal()
                  })
                  .catch((error) => alert(error.message))
              }}
            >
              저장
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default AddExternalContent
