import React, { useRef } from 'react'

import Location from '../../common/component/Location'
import Button from '../../common/component/Button'
import InputPassword, { runPasswordValidator } from '../../common/component/InputPassword'
import InputPasswordConfirm, {
  runPasswordCheckValidator,
  validatePasswordConfirm,
} from '../../common/component/InputPasswordConfirm'

import UserInfoMenu from '../component/UserInfoMenu'
import { userInfoRepository } from '../repository/UserInfoRepository'

import { errorMessageHandler } from '../../common/utils/AlertMessage'
import { validatePasswordFormat } from '../../common/utils/FormatChecker'

const UserInfoPassword = () => {
  const oldPasswordRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const passwordConfirmRef = useRef<HTMLInputElement>(null)

  const validateOldPassword = (oldPassword: string) => {
    return oldPassword.trim() !== ''
  }

  const clearForm = () => {
    if (oldPasswordRef.current) {
      oldPasswordRef.current.value = ''
    }
    if (passwordRef.current) {
      passwordRef.current.value = ''
    }
    if (passwordConfirmRef.current) {
      passwordConfirmRef.current.value = ''
    }
  }

  const validateForm = (oldPassword: string, newPassword: string): boolean => {
    const isValidOldPassword = validateOldPassword(oldPassword)
    if (oldPassword.trim() === '') {
      alert('기존 비밀번호를 입력하세요.')
    }
    const isValidNewPassword = validatePasswordFormat(newPassword)
    if (!isValidNewPassword) {
      runPasswordValidator(passwordRef)
    }
    const passwordConfirm = passwordConfirmRef.current?.value ?? ''
    const isValidPasswordConfirm = validatePasswordConfirm(passwordRef, passwordConfirm)
    if (!isValidPasswordConfirm) {
      runPasswordCheckValidator(passwordConfirmRef)
    }
    return isValidOldPassword && isValidNewPassword && isValidPasswordConfirm
  }

  const changePasswordSuccessHandler = () => {
    alert('정상적으로 비밀번호가 변경되었습니다.')
    clearForm()
  }

  const requestPasswordChange = () => {
    const oldPassword = oldPasswordRef.current?.value ?? ''
    const newPassword = passwordRef.current?.value ?? ''
    if (!validateForm(oldPassword, newPassword)) {
      return
    }
    userInfoRepository
      .changeUserPassword({
        oldPassword,
        newPassword,
      })
      .then(changePasswordSuccessHandler)
      .catch(errorMessageHandler)
  }

  return (
    <main>
      <div className="container">
        <Location paths={['마이페이지', '비밀번호변경']} />
        <h1 className="head">마이페이지</h1>
        <div className="content-wrap">
          <UserInfoMenu pageType="PasswordChange" />
          <section className="content">
            <h1 className="normal-head">비밀번호변경</h1>
            <form>
              <ul className="my-form">
                <li>
                  <label>기존 비밀번호</label>
                  <input ref={oldPasswordRef} type="password" aria-label="old-password-input" />
                </li>
                <li></li>
                <li>
                  <label>새로운 비밀번호</label>
                  <InputPassword ref={passwordRef} />
                </li>
                <li>
                  <label>새로운 비밀번호 확인</label>
                  <InputPasswordConfirm ref={passwordConfirmRef} passwordRef={passwordRef} />
                </li>
              </ul>
            </form>
            <div className="button-wrap destro">
              <Button ariaLabel="modify-button" onClick={requestPasswordChange}>
                변경
              </Button>
            </div>
          </section>
        </div>
      </div>
    </main>
  )
}

export default UserInfoPassword
