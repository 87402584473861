import React, { useContext, useEffect, useState } from 'react'

import Location from '../../common/component/Location'
import InputEmail from '../../common/component/InputEmail'
import Button from '../../common/component/Button'
import { Context as UserInfoContext } from '../provider/UserInfoProvider'

import UserInfoMenu from '../component/UserInfoMenu'
import { userInfoRepository } from '../repository/UserInfoRepository'

import { errorMessageHandler } from '../../common/utils/AlertMessage'

const UserInfo = () => {
  const { userInfo, loadUserInfo } = useContext(UserInfoContext)!
  const [nickName, setNickName] = useState<string>(userInfo?.nickName ?? '')

  useEffect(() => {
    if (userInfo) {
      setNickName(userInfo.nickName)
    }
  }, [userInfo])

  const updateSuccessHandler = () => {
    alert('사용자 정보가 변경되었습니다.')
    loadUserInfo()
  }

  const updateUserInfoHandler = () => {
    if (!userInfo || !userInfo?.id) {
      return
    }
    userInfoRepository
      .updateUserInfo({ nickName })
      .then(updateSuccessHandler)
      .catch(errorMessageHandler)
  }

  const deleteUserInfoHandler = () => {
    if (
      window.confirm(
        '회원 탈퇴 하시겠습니까? 탈퇴하시면 내서재를 비롯한 계정 정보가 전부 삭제되며 복구할 수 없습니다.'
      )
    ) {
      userInfoRepository.deleteUserInfo().then(() => {
        alert('탈퇴했습니다.')
        window.location.reload()
      })
    }
  }

  return (
    <main>
      <div className="container">
        <Location paths={['마이페이지', '개인정보수정']} />
        <h1 className="head">마이페이지</h1>
        <div className="content-wrap">
          <UserInfoMenu pageType="UserInfo" />
          <section className="content">
            <h1 className="normal-head">개인정보수정</h1>
            <form>
              <ul className="my-form">
                <li>
                  <label>이메일</label>
                  <InputEmail readOnly={true} value={userInfo?.email ?? ''} />
                </li>
                <li>
                  <label>닉네임</label>
                  <input
                    type="text"
                    aria-label="nickname-input"
                    onChange={(event) => setNickName(event.target.value)}
                    value={nickName}
                  />
                </li>
              </ul>
            </form>
            <div className="button-wrap destro">
              <Button onClick={updateUserInfoHandler}>정보수정</Button>
            </div>
            <div className="button-wrap destro">
              <Button onClick={deleteUserInfoHandler}>회원탈퇴</Button>
            </div>
          </section>
        </div>
      </div>
    </main>
  )
}

export default UserInfo
