import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Context as UserInfoContext } from '../../user/provider/UserInfoProvider'
import { Context as SearchConditionContext } from '../../article/provider/SearchConditionProvider'
import { Context as AdvancedSearchContext } from '../provider/AdvancedSearchModalProvider'
import { parseCookie } from '../repository/AccessTokenRepository'
import { organizationInfoRepository } from '../../user/repository/OrganizationInfoRepository'
import NiceSelect from './NiceSelect'
import LinkButton from './LinkButton'
import { isEmpty, isNotEmpty } from '../utils/TextUtils'
import { isTactoEnabled } from '../utils/FeatureFlag'

interface Props {
  isHome?: boolean
}

type SelectSearchFieldType = 'article' | 'journal' | 'author' | 'publisher'

const Header = ({ isHome }: Props) => {
  const navigate = useNavigate()
  const { userInfo, isLoggedIn, logout } = useContext(UserInfoContext)!
  const { openModal } = useContext(AdvancedSearchContext)!
  const headerRef = useRef<HTMLHeadElement>(null)

  const searchConditions = useContext(SearchConditionContext)!
  const [searchText, setSearchText] = useState(searchConditions?.searchText)
  const [selectedSearchField, setSelectedSearchField] = useState<SelectSearchFieldType>('article')
  const [organizationName, setOrganizationName] = useState(parseCookie('ORGANIZATION_NAME') || '')

  const location = useLocation()
  useEffect(() => {
    setSearchText(searchConditions.searchText)
  }, [location, searchConditions.searchText])

  useEffect(() => {
    const organizationName = decodeURI(parseCookie('ORGANIZATION_NAME') || '')
    if (organizationName.trim() !== '') {
      setOrganizationName(organizationName)
    } else {
      organizationInfoRepository.getOrganizationInfo().then((response) => {
        const name = response.name
        if (name.trim() !== '') {
          setOrganizationName(name)
        }
      })
    }
  }, [location])

  useEffect(() => {
    const scrollEvent = () => {
      if (window.scrollY > 0 && window.outerWidth > 768) {
        headerRef.current?.classList.add('scroll')
      } else {
        headerRef.current?.classList.remove('scroll')
      }
    }
    window.addEventListener('scroll', scrollEvent)
    return () => {
      window.removeEventListener('scroll', scrollEvent)
    }
  }, [])

  useEffect(() => {
    setSearchText(searchConditions.searchText)
    // eslint-disable-next-line
  }, [searchConditions.manualSearchTrigger])

  const moveToLogin = () => {
    navigate('/login')
  }

  const moveToJoin = () => {
    navigate('/join/intro')
  }

  const moveToUserInfo = () => {
    navigate('/user-info')
  }

  const moveToMyArticles = () => {
    if (!isLoggedIn) {
      alert('회원만 이용할 수 있습니다.')
      return
    }
    if (userInfo?.articlesStorages.length) {
      navigate(`/user-info/storages/${userInfo?.articlesStorages[0].id}`)
    } else {
      navigate(`/user-info/storages`)
    }
  }

  const moveToKnotes = () => {
    if (!isLoggedIn) {
      alert('회원만 이용할 수 있습니다.')
      return
    }
    navigate('/knotes')
  }

  const moveToServiceInformation = () => {
    navigate('/information/service')
  }

  const search = () => {
    if (isEmpty(searchText)) {
      alert('검색어를 입력하세요')
      return
    }
    searchConditions.clearConditions()
    switch (selectedSearchField) {
      case 'article': {
        searchConditions.setSearchText(searchText || '')
        searchConditions.setManualSearchTrigger(!searchConditions.manualSearchTrigger)
        navigate('/article-search')
        break
      }
      case 'author': {
        navigate(`/authors?name=${encodeURI(searchText)}`)
        break
      }
      case 'journal': {
        navigate(`/journals?name=${encodeURI(searchText)}`)
        break
      }
      case 'publisher': {
        navigate(`/publishers?name=${encodeURI(searchText)}`)
        break
      }
    }
    searchConditions.setManualSearchTrigger(!searchConditions?.manualSearchTrigger)
  }

  return (
    <header ref={headerRef} className={`${isHome ? 'home' : ''}`}>
      <div className="neo-container">
        <h1>
          <a aria-label="logo-to-main" href="/main">
            <i className="blind">K·DISCOVERY·LOGO</i>
          </a>
        </h1>
        <nav aria-label="header-gnb" className="gnb">
          <LinkButton path="/article-search" onClick={() => searchConditions.setSearchText('')}>
            논문/특허
          </LinkButton>
          <LinkButton path="/journals" onClick={() => searchConditions.setSearchText('')}>
            저널
          </LinkButton>
          <LinkButton path="/authors" onClick={() => searchConditions.setSearchText('')}>
            저자
          </LinkButton>
          <LinkButton path="/publishers" onClick={() => searchConditions.setSearchText('')}>
            발행기관
          </LinkButton>
          <LinkButton path="/trend-issue" onClick={() => searchConditions.setSearchText('')}>
            트렌드이슈
          </LinkButton>
        </nav>
        <nav className="util">
          <div>
            {isLoggedIn ? (
              <Fragment>
                {organizationName !== '' && <LinkButton>기관({organizationName})</LinkButton>}
                {userInfo && (
                  <LinkButton onClick={moveToUserInfo}>{userInfo.nickName} 님</LinkButton>
                )}
                <LinkButton onClick={logout}>로그아웃</LinkButton>
              </Fragment>
            ) : (
              <Fragment>
                {organizationName !== '' && <LinkButton>기관({organizationName})</LinkButton>}
                <LinkButton onClick={moveToLogin}>로그인</LinkButton>
                <LinkButton onClick={moveToJoin}>회원가입</LinkButton>
              </Fragment>
            )}
            <LinkButton onClick={moveToMyArticles}>내서재</LinkButton>
            {isTactoEnabled() && <LinkButton onClick={moveToKnotes}>knotes</LinkButton>}
            <LinkButton onClick={moveToServiceInformation}>이용안내</LinkButton>
          </div>
        </nav>
      </div>
      {!isHome && (
        <fieldset className="search">
          <NiceSelect
            ariaLabel="search-type"
            options={
              new Map([
                ['article', '논문/특허'],
                ['journal', '저널'],
                ['author', '저자'],
                ['publisher', '발행기관'],
              ])
            }
            onSelectCallback={(value) => {
              setSelectedSearchField(value as SelectSearchFieldType)
            }}
          />
          <input
            aria-label="header-search-input"
            type="text"
            placeholder="어떤 학술자료를 찾으시나요?"
            onChange={(e) => setSearchText(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') search()
            }}
            value={searchText}
          />
          {isNotEmpty(searchText) && (
            <button
              className="simple-clear-button"
              aria-label="clear-button"
              onClick={() => setSearchText('')}
            />
          )}
          <button className="simple-search-button" aria-label="search-button" onClick={search} />
          <button className="detail-search-button" onClick={openModal}>
            상세검색
          </button>
        </fieldset>
      )}
    </header>
  )
}

export default Header
