import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import ModalWindow from '../../common/component/ModalWindow'
import DeleteRestoreTactoItem from './DeleteRestoreTactoItem'

import WorkspaceMaterial from '../domain/WorkspaceMaterial'

import { updatedDate } from '../util/time-formatter'
import { deleteMaterial, restoreMaterial } from '../repository/TactoWorkspaceRepository'

type Props = {
  materials: WorkspaceMaterial[]
  isInTrash: boolean
  refreshItems: () => void
}

const MaterialList = ({ materials, isInTrash, refreshItems }: Props) => {
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = React.useState<WorkspaceMaterial | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false)
  const [restoreModalOpen, setRestoreModalOpen] = React.useState<boolean>(false)

  const moveToProjectPage = useCallback(
    (id: number) => {
      if (!isInTrash) {
        navigate(`/knotes/projects/${id}?menu=materials`)
      }
    },
    [isInTrash, navigate]
  )

  return (
    <>
      <ModalWindow isOpen={restoreModalOpen} closeModal={() => setRestoreModalOpen(false)}>
        <DeleteRestoreTactoItem
          title="자료 복원"
          message="복원하시겠습니까?"
          isDeleteMode={false}
          confirmCallback={() => {
            if (selectedItem) {
              restoreMaterial(selectedItem.id).then(refreshItems)
            }
          }}
          closeModal={() => setRestoreModalOpen(false)}
        />
      </ModalWindow>
      <ModalWindow isOpen={deleteModalOpen} closeModal={() => setDeleteModalOpen(false)}>
        <DeleteRestoreTactoItem
          title="자료 삭제"
          message="삭제하시겠습니까?"
          isDeleteMode={true}
          confirmCallback={() => {
            if (selectedItem) {
              deleteMaterial(selectedItem.id).then(refreshItems)
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
        />
      </ModalWindow>
      <div className="workspace-items" aria-label="material-list">
        {materials.map((item, index) => (
          <div className="workspace-item-wrap" key={index}>
            <div className="workspace-item" onClick={() => moveToProjectPage(item.projectId)}>
              <div className="project-name">{item.projectName}</div>
              <div className="name">{item.title}</div>
              <div className="content">
                <div className="left-container">
                  <div>{item.publishYear}</div>
                  <div className="divider" />
                  <div>{item.publisher}</div>
                  <div className="divider" />
                  <div>{item.authors}</div>
                </div>
                <div className="attachment-memo-container">
                  <div className={item.attachmentId ? 'badge existed' : 'badge'}>
                    <div className="icon attachment" />
                    첨부
                  </div>
                  <div className={item.memoId ? 'badge existed' : 'badge'}>
                    <div className="icon memo" />
                    메모
                  </div>
                  <div className="updated-at">
                    {updatedDate(item.updatedAt, isInTrash ? '삭제' : '수정')}
                  </div>
                </div>
              </div>
            </div>
            {isInTrash && (
              <div className="trash-buttons">
                <button
                  className="restore"
                  onClick={(e) => {
                    e.preventDefault()
                    setRestoreModalOpen(true)
                    setSelectedItem(item)
                  }}
                >
                  복원
                </button>
                <button
                  className="delete"
                  onClick={(e) => {
                    e.preventDefault()
                    setDeleteModalOpen(true)
                    setSelectedItem(item)
                  }}
                  aria-label="delete"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default MaterialList
