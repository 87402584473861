import Sentence from '../domain/Sentence'
import axios from 'axios'

export type SearchSentenceRequest = {
  searchText: string
  categoryId: number | null
  type: string | null
  year: string | null
  size: number
  language: string
}

export const searchSentences = async (request: SearchSentenceRequest): Promise<Sentence[]> => {
  const { data } = await axios.get('/app/api/sentences/search', {
    params: {
      ...request,
    },
  })
  return data
}

export const getParaphrasingSentence = async (
  content: string,
  categoryId: number
): Promise<string> => {
  const { data } = await axios.get('/app/api/sentences/paraphrasing', {
    params: {
      content,
      categoryId,
    },
  })
  return data
}
