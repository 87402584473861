import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import MyArticlesStorageBox from './MyArticlesStorageBox'

type Props = {
  pageType: 'UserInfo' | 'PasswordChange' | 'Storages'
}

const UserInfoMenu = ({ pageType }: Props) => {
  const navigate = useNavigate()

  const moveToUserInfo = () => {
    navigate('/user-info')
  }

  const moveToPasswordChange = () => {
    navigate('/user-info/password')
  }

  const moveToUserStorages = (storageId: number) => {
    navigate(`/user-info/storages/${storageId}`)
  }

  return (
    <aside className="aside">
      <nav className="lnb">
        <ul id="tree">
          {pageType !== 'Storages' ? (
            <Fragment>
              <li onClick={moveToUserInfo}>
                <p className="head">
                  <a href="/app/api/knotes-backup/topic-trees" download="topic-tree.xlsx">
                    knotes 토픽트리 백업
                  </a>
                </p>
              </li>
              <li onClick={moveToUserInfo}>
                <p className="head">
                  <a href="/app/api/knotes-backup/materials" download="materials.xlsx">
                    knotes 자료 백업
                  </a>
                </p>
              </li>
              <li onClick={moveToUserInfo}>
                <p className="head">
                  <span className={`${pageType === 'UserInfo' && 'on'}`}>개인정보수정</span>
                </p>
              </li>
              <li onClick={moveToPasswordChange}>
                <p className="head">
                  <span className={`${pageType === 'PasswordChange' && 'on'}`}>비밀번호변경</span>
                </p>
              </li>
            </Fragment>
          ) : (
            <li className="on">
              <p className="toggle head">
                <span className="on">보관함관리</span>
                <i></i>
              </p>
              <MyArticlesStorageBox selectStorage={moveToUserStorages} />
            </li>
          )}
        </ul>
      </nav>
    </aside>
  )
}

export default UserInfoMenu
