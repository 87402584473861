import TopicTreeNode from '../domain/TopicTreeNode'
import axios from 'axios'
import MaterialDetail from '../domain/MaterialDetail'

export const getSuggestedTopicTreeByDocument = async (
  contentIdType: string,
  reference: string
): Promise<TopicTreeNode> => {
  const { data } = await axios.get('/app/api/external-content/suggestion-topics', {
    params: {
      contentIdType,
      id: reference,
    },
  })
  return data
}

export const getSuggestedTopicTreeByText = async (reference: string): Promise<TopicTreeNode> => {
  const { data } = await axios.post('/app/api/external-content/suggestion-topics/text', {
    reference,
  })
  return data
}

export const getExternalContent = async (
  contentIdType: string,
  id: string
): Promise<MaterialDetail> => {
  const { data } = await axios.get('/app/api/external-content', {
    params: {
      contentIdType,
      id,
    },
  })
  return data
}
