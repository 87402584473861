import { useNavigate } from 'react-router-dom'
import React, { useCallback } from 'react'
import { updatedDate } from '../util/time-formatter'
import WorkspaceMemo from '../domain/WorkspaceMemo'

type Props = {
  memos: WorkspaceMemo[]
}

const MemoList = ({ memos }: Props) => {
  const navigate = useNavigate()

  const moveToProjectPage = useCallback(
    (id: number) => {
      navigate(`/knotes/projects/${id}?menu=memos`)
    },
    [navigate]
  )

  return (
    <div className="workspace-items" aria-label="memo-list">
      {memos.map((item, index) => (
        <div className="workspace-item-wrap" key={`${item.memoId}-${index}`}>
          <div className="workspace-item" onClick={() => moveToProjectPage(item.projectId)}>
            <div className="project-name">{item.projectName}</div>
            <div className="name">{item.title}</div>
            <div className="content">
              <div className="left-container">
                <div>{item.publishYear}</div>
                <div className="divider" />
                <div>{item.publisher}</div>
                <div className="divider" />
                <div>{item.authors}</div>
              </div>
            </div>
            <div className="attachment-memo-container">
              <div className={item.attachmentId ? 'badge existed' : 'badge'}>
                <div className="icon attachment" />
                첨부
              </div>
              <div className={item.memoId ? 'badge existed' : 'badge'}>
                <div className="icon memo" />
                메모
              </div>
              <div className="memo-title">{item.memoTitle}</div>
            </div>
            <div className="updated-at">{updatedDate(item.updatedAt)}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MemoList
