import React, { Fragment, useCallback, useMemo, useState } from 'react'
import {
  getSuggestedTopicTreeByDocument,
  getSuggestedTopicTreeByText,
} from '../repository/ExternalContentRepository'
import TopicTreeNode from '../domain/TopicTreeNode'
import DocumentOption from '../domain/DocumentOption'
import { RightSection } from './TopicTreeManagement'
import ReactDom from 'react-dom'

type Props = {
  onCreateTopicTree: (topicTree: TopicTreeNode) => void
  setRightSection: (rightSection: RightSection) => void
}

function drawTree(treeNodeMap: { [key in string]: TopicTreeNode }) {
  return Object.values(treeNodeMap).map((value) => {
    return (
      <Fragment key={value.id}>
        <div className={`tree-node-item children`}>
          <li>{value.name}</li>
        </div>
        {value.children && <ul className="children">{drawTree(value.children)}</ul>}
      </Fragment>
    )
  })
}

const CreateTopicByDocumentSection = ({ onCreateTopicTree, setRightSection }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reference, setReference] = useState<string>('')
  const [documentOption, setDocumentOption] = useState<DocumentOption>('')
  const [topicTree, setTopicTree] = useState<TopicTreeNode | null>(null)
  const optionTitle = useMemo(() => {
    switch (documentOption) {
      case 'ApplicationNo':
        return '출원번호'
      case 'URL':
        return 'URL (e.g. https://kdiscovery.kr/articles/1)'
      case 'Text':
        return '텍스트'
      default:
        return documentOption
    }
  }, [documentOption])

  const OptionInput = useMemo(() => {
    switch (documentOption) {
      case 'Text':
        return (
          <textarea
            aria-label="text-area-source"
            disabled={topicTree !== null}
            value={reference}
            onChange={(e) => setReference(e.target.value)}
          />
        )
      case '':
        return <></>
      default:
        return (
          <input
            type="text"
            aria-label="text-source"
            disabled={topicTree !== null}
            value={reference}
            onChange={(e) => setReference(e.target.value)}
          />
        )
    }
  }, [documentOption, reference, topicTree])

  const onCreateTopicTreeHandler = useCallback(() => {
    if (documentOption === 'Text' && (reference.length < 1 || reference.length > 2000)) {
      alert('문장은 최소 1자, 최대 2000자입니다.')
      return
    }
    setIsLoading(true)
    let promiseTopicTree = null
    if (documentOption === 'Text') {
      promiseTopicTree = getSuggestedTopicTreeByText(reference)
    } else {
      promiseTopicTree = getSuggestedTopicTreeByDocument(documentOption, reference)
    }
    promiseTopicTree
      .then((response) => setTopicTree(response))
      .catch((error) => {
        if (error?.message) {
          alert(error.message)
        }
      })
      .finally(() => setIsLoading(false))
  }, [documentOption, reference])

  const onChangeDocumentOption = (option: DocumentOption) => {
    setReference('')
    setDocumentOption(option)
  }

  return (
    <>
      {isLoading &&
        ReactDom.createPortal(
          <div className="spinner-wrap">
            <div id="dim" />
            <div className="spinner" />
          </div>,
          document.getElementById('modal')!
        )}
      <div className="create-topic-by-document">
        <div className="title">문서에서 만들기</div>
        {topicTree === null && (
          <div className="creation-container" aria-label="creation-topic-based-document">
            <div className="document-options">
              <div
                className={`document-option ${documentOption === 'DOI' && 'on'}`}
                onClick={() => onChangeDocumentOption('DOI')}
              >
                DOI
              </div>
              <div
                className={`document-option ${documentOption === 'PMID' && 'on'}`}
                onClick={() => onChangeDocumentOption('PMID')}
              >
                PMID
              </div>
              <div
                className={`document-option ${documentOption === 'PMCID' && 'on'}`}
                onClick={() => onChangeDocumentOption('PMCID')}
              >
                PMCID
              </div>
              <div
                className={`document-option ${documentOption === 'ApplicationNo' && 'on'}`}
                onClick={() => onChangeDocumentOption('ApplicationNo')}
              >
                출원번호
              </div>
              <div
                className={`document-option ${documentOption === 'URL' && 'on'}`}
                onClick={() => onChangeDocumentOption('URL')}
              >
                URL
              </div>
              <div
                className={`document-option ${documentOption === 'Text' && 'on'}`}
                onClick={() => onChangeDocumentOption('Text')}
              >
                텍스트
              </div>
            </div>
            <div className="option-description">
              위 항목 중 하나를 선택하여 입력, 또는 파일을 첨부해주세요.
            </div>
            {documentOption && (
              <div className="selection-form" aria-label="selection">
                <div className="selection-label">{optionTitle}</div>
                <div className={`selection-input ${documentOption === 'Text' && 'text'}`}>
                  {OptionInput}
                </div>
              </div>
            )}
            <div className="buttons">
              <button className="cancel" onClick={() => setRightSection('')}>
                취소
              </button>
              <button className="confirm" onClick={onCreateTopicTreeHandler}>
                토픽 만들기
              </button>
            </div>
          </div>
        )}
        {topicTree && (
          <div className="suggestion-container" aria-label="suggestion-topic-based-document">
            <div className="selection-form" aria-label="selection">
              <div className="selection-label">{optionTitle}</div>
              <div className={`selection-input ${documentOption === 'Text' && 'text'}`}>
                {OptionInput}
              </div>
            </div>
            <div className="topic-tree">{drawTree({ [topicTree.id]: topicTree })}</div>
            <div className="buttons">
              <button className="cancel" onClick={() => setTopicTree(null)}>
                취소
              </button>
              <button className="confirm" onClick={() => onCreateTopicTree(topicTree)}>
                적용
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default CreateTopicByDocumentSection
