import React, { useCallback, useEffect, useState } from 'react'

import Pagination from '../../common/component/Pagination'
import { useCheckbox } from '../../common/hooks/useCheckbox'
import CheckBox from '../../common/component/CheckBox'
import AllCheckBox from '../../common/component/AllCheckBox'
import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'
import { usePageable } from '../../common/hooks/usePageable'

import Article from '../../article/domain/Article'
import { articleRepository } from '../../article/repository/ArticleRepository'

import TopicTreeNode from '../domain/TopicTreeNode'
import { addMaterials } from '../repository/MaterialsRepository'

type Props = {
  projectId: number
  topicNode: TopicTreeNode | null
  fetchMaterials: () => void
  closeModal: () => void
}

const TopicMaterialSearch = ({ projectId, topicNode, fetchMaterials, closeModal }: Props) => {
  const { pageInfo, setupPage } = usePageable()
  const [badgeMessage, setBadgeMessage] = useState<string>('')
  const [articles, setArticles] = useState<Article[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked } =
    useCheckbox()

  const moveToPage = useCallback(
    (currentPage: number) => {
      clearAllChecked()
      setBadgeMessage('자료수집중')
      articleRepository
        .searchInArticles(searchText || topicNode?.name || '', [], [], [], [], [], [], {
          page: currentPage,
          size: 7,
        })
        .then((response) => {
          if (response.articles.content.length === 0) {
            setBadgeMessage('결과없음')
          } else {
            setBadgeMessage('자료수집완료')
          }
          setupPage(response.articles, currentPage)
          setArticles(response.articles.content)
        })
        .catch(() => setBadgeMessage('결과없음'))
    },
    [topicNode, searchText, setupPage, clearAllChecked]
  )

  const onAddArticles = useCallback(() => {
    if (topicNode) {
      if (checkedItems.size === 0) {
        alert('선택한 자료가 없습니다.')
        return
      }
      addMaterials(projectId, topicNode.id, Array.from(checkedItems))
        .then(() => {
          fetchMaterials()
          closeModal()
        })
        .catch((error) => alert(error.message))
    }
  }, [checkedItems, closeModal, fetchMaterials, projectId, topicNode])

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [])

  return (
    topicNode && (
      <div className="tacto-material-search-modal" aria-label="tacto-material-search-modal">
        <div className="header-section">
          <div className="title-container">
            <div className="badge">
              <div className="icon" />
              {badgeMessage}
            </div>
            <div className="title">{topicNode.name}</div>
          </div>
          <div className="description">{topicNode.description}</div>
        </div>
        <div className="top-section">
          <AllCheckBox
            isAllChecked={isAllChecked}
            onAllCheckHandler={(e) => onAllCheckHandler(e, articles)}
          />
          <div className="search">
            <input
              type="text"
              placeholder="검색어를 입력해주세요"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button aria-label="search-button" onClick={() => moveToPage(0)} />
          </div>
        </div>
        <div className="project-item-list">
          {articles.map((article) => (
            <div key={article.id} className="project-item-wrap">
              <CheckBox
                onChange={(event) => onCheckedHandler(event, article.id)}
                className="sgl-check unit"
                ariaLabel={`checkbox-${article.id}`}
                isAllChecked={isAllChecked}
                checked={checkedItems.has(article.id)}
              />
              <div className="material">
                <div className="title" dangerouslySetInnerHTML={{ __html: article.title }} />
                <div className="content">
                  <div>{article.articleSource.publishYear}</div>
                  <div className="divider" />
                  <div>{article.articleSource.publisher}</div>
                  <div className="divider" />
                  <div>{article.authors.map((author) => author.name).join(' · ')}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-wrap">
          <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
        </div>
        <div className="buttons">
          <CancelButton name="취소" onClick={closeModal} />
          <ConfirmButton name="선택 자료 보관" onClick={onAddArticles} />
        </div>
      </div>
    )
  )
}

export default TopicMaterialSearch
