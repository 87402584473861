import NiceSelect from '../../common/component/NiceSelect'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import CheckBox from '../../common/component/CheckBox'
import { useCheckbox } from '../../common/hooks/useCheckbox'
import Pagination from '../../common/component/Pagination'
import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'
import { usePageable } from '../../common/hooks/usePageable'

import { Context as UserInfoContext } from '../../user/provider/UserInfoProvider'
import { userStorageRepository } from '../../user/repository/UserStorageRepository'
import ArticlesStorage from '../../user/domain/ArticlesStorage'

import Article from '../../article/domain/Article'
import { addMaterials } from '../repository/MaterialsRepository'

type Props = {
  projectId: number
  topicId: string
  fetchMaterials: () => void
  closeModal: () => void
}

const SearchInMyStorage = ({ projectId, topicId, fetchMaterials, closeModal }: Props) => {
  const { userInfo } = useContext(UserInfoContext)!
  const { pageInfo, setupPage } = usePageable()
  const [storageOptions, setStorageOptions] = useState<Map<string, string>>(new Map())
  const [selectedStorage, setSelectedStorage] = useState<ArticlesStorage>()
  const [articles, setArticles] = useState<Article[]>([])
  const { checkedItems, onCheckedHandler, clearAllChecked } = useCheckbox()
  const [searchText, setSearchText] = useState<string>('')

  const moveToPage = useCallback(
    (currentPage: number) => {
      if (selectedStorage) {
        clearAllChecked()
        userStorageRepository
          .getArticlesInStorage(selectedStorage.id, searchText, { page: currentPage, size: 7 })
          .then((response) => {
            setupPage(response, currentPage)
            setArticles(response.content)
          })
      }
    },
    [selectedStorage, clearAllChecked, searchText, setupPage]
  )

  const onSelectCallback = useCallback(
    (key: string) => {
      if (userInfo) {
        const selectedStorage = userInfo.articlesStorages.find((storage) => `${storage.id}` === key)
        setSelectedStorage(selectedStorage)
      }
    },
    [userInfo]
  )

  const onAddMaterials = useCallback(() => {
    if (checkedItems.size === 0) {
      alert('선택한 자료가 없습니다.')
      return
    }
    addMaterials(projectId, topicId, Array.from(checkedItems))
      .then(() => {
        fetchMaterials()
        closeModal()
      })
      .catch((error) => alert(error.message))
  }, [projectId, topicId, checkedItems, fetchMaterials, closeModal])

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [selectedStorage])

  useEffect(() => {
    if (userInfo && userInfo.articlesStorages.length > 0) {
      const storage = userInfo.articlesStorages[0]
      setSelectedStorage(storage)
      const options = new Map()
      userInfo.articlesStorages.map((tree) => options.set(`${tree.id}`, tree.storageName))
      setStorageOptions(options)
    }
  }, [userInfo])

  return (
    <div className="search-in-my-storage-modal" aria-label="search-in-my-storage-modal">
      <div className="title">내 서재에서 가져오기</div>
      <div className="top-section">
        <NiceSelect
          ariaLabel={'my-storage'}
          onSelectCallback={onSelectCallback}
          options={storageOptions}
          selectedValue={selectedStorage?.storageName || ''}
        />
        <div className="search">
          <input
            type="text"
            placeholder="검색어를 입력해주세요"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button aria-label="search-button" onClick={() => moveToPage(0)} />
        </div>
      </div>
      <div className="project-item-list">
        {articles.map((article) => (
          <div key={article.id} className="project-item-wrap">
            <CheckBox
              onChange={(event) => onCheckedHandler(event, article.id)}
              className="sgl-check unit"
              ariaLabel={`checkbox-${article.id}`}
              checked={checkedItems.has(article.id)}
            />
            <div className="material">
              <div className="title">{article.title}</div>
              <div className="content">
                <div>{article.articleSource.publishYear}</div>
                <div className="divider" />
                <div>{article.articleSource.publisher}</div>
                <div className="divider" />
                <div>{article.authors.map((author) => author.name).join(' · ')}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination-wrap">
        <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton isFitContent={true} name="선택 자료 가져오기" onClick={onAddMaterials} />
      </div>
    </div>
  )
}

export default SearchInMyStorage
