// eslint-disable-next-line
export const debounce = (func: Function, timeout: number) => {
  let timer: NodeJS.Timeout
  return (...arg: unknown[]) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      func(...arg)
    }, timeout)
    return timer
  }
}
