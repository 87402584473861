import axios from 'axios'

export const setupAxiosErrorResponseFormat = () => {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response) {
        const { data, status: httpStatus } = error.response
        if (httpStatus === 401 && !document.location.href.includes('/main')) {
          alert('세션이 만료되었습니다. 다시 로그인 바랍니다.')
          document.location.replace('/main')
        }
        return Promise.reject({ ...data, httpStatus })
      }
      return Promise.reject(error)
    }
  )
}
