import axios from 'axios'
import TactoProject from '../domain/TactoProject'
import TactoProjectDetail from '../domain/TactoProjectDetail'
import TopicTreeNode from '../domain/TopicTreeNode'

export const createProject = async (requestBody: TactoProject): Promise<void> => {
  await axios.post('/app/api/tacto/projects', requestBody)
}

export const getProject = async (id: number): Promise<TactoProjectDetail> => {
  const { data } = await axios.get(`/app/api/tacto/projects/${id}`)
  return data
}

export const saveTopicTree = async (projectId: number, requestBody: TopicTreeNode) => {
  await axios.post(`/app/api/tacto/projects/${projectId}/topic-tree`, requestBody)
}

export const updateProject = async (id: number, requestBody: TactoProject) => {
  await axios.put(`/app/api/tacto/projects/${id}`, requestBody)
}

export const getAllRootNodes = async (): Promise<TopicTreeNode[]> => {
  const { data } = await axios.get('/app/api/tacto/projects/all-topic-trees')
  return data
}

export const getTopicNodesInTopicMap = async (keyword: string): Promise<TopicTreeNode[]> => {
  const { data } = await axios.get('/app/api/tacto/projects/topic-trees-in-map', {
    params: {
      keyword,
    },
  })
  return data
}

export const deleteMarkingProject = async (id: number) => {
  await axios.put(`/app/api/tacto/projects/${id}/delete-marking`)
}
