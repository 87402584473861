import React, { useEffect, useState } from 'react'
import { getParaphrasingSentence } from '../repository/SentenceRepository'
import ReactDom from 'react-dom'

type Props = {
  ariaLabel: string
  sentence: string
  categoryId: number | null
}

const SuggestionSentence = ({ ariaLabel, sentence, categoryId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sentenceSnapshot, setSentenceSnapshot] = useState<string>(sentence)
  const [paraphrasedSentence, setParaphrasedSentence] = useState<string>('')
  const copyOnClipboard = (value: string) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(value)
    }
  }

  useEffect(() => {
    if (sentenceSnapshot !== sentence) {
      setSentenceSnapshot(sentence)
      setParaphrasedSentence('')
    }
  }, [sentence, sentenceSnapshot])

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isLoading])

  return (
    <>
      {isLoading &&
        ReactDom.createPortal(
          <div className="spinner-wrap fixed">
            <div id="dim" />
            <div className="spinner" />
          </div>,
          document.getElementById('modal')!
        )}
      <div className="suggestion-sentence" aria-label={ariaLabel}>
        <div className="sentence">
          {sentenceSnapshot}
          <div className="buttons">
            <button onClick={() => copyOnClipboard(sentence)}>
              <div className="copy icon" />
              복사하기
            </button>
            <button
              onClick={() => {
                setIsLoading(true)
                getParaphrasingSentence(sentence, categoryId || 0)
                  .then((response) => setParaphrasedSentence(response))
                  .finally(() => setIsLoading(false))
              }}
            >
              <div className="paraphrasing icon" />
              패러프레이징
            </button>
          </div>
        </div>
        {paraphrasedSentence && (
          <div className="sentence">
            {paraphrasedSentence}
            <div className="buttons">
              <button onClick={() => copyOnClipboard(paraphrasedSentence)}>
                <div className="copy icon" />
                복사하기
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SuggestionSentence
