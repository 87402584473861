import PageRequest from '../../common/domain/PageRequest'
import Page from '../../common/domain/Page'
import TactoArticle from '../domain/TactoArticle'
import SimpleTactoArticle from '../domain/SimpleTactoArticle'
import axios from 'axios'

export const getTactoArticles = async (
  projectId: number,
  keyword: string,
  pageRequest: PageRequest
): Promise<Page<TactoArticle>> => {
  const { data } = await axios.get(`/app/api/tacto-articles/projects/${projectId}/articles`, {
    params: {
      ...pageRequest,
      keyword,
    },
  })
  return data
}

export const saveTactoArticle = async (
  projectId: number,
  requestBody: SimpleTactoArticle
): Promise<number> => {
  const { data } = await axios.put(`/app/api/tacto-articles/projects/${projectId}`, requestBody)
  return data
}

export const getTactoArticle = async (
  projectId: number,
  id: number
): Promise<SimpleTactoArticle> => {
  const { data } = await axios.get(`/app/api/tacto-articles/projects/${projectId}/articles/${id}`)
  return data
}

export const deleteMarkingTactoArticles = async (tactoArticleIds: number[]): Promise<void> => {
  await axios.put(`/app/api/tacto-articles/delete-marking`, tactoArticleIds, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
