import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

type Props = {
  title: string
  message: string
  isDeleteMode: boolean
  confirmCallback: () => void
  closeModal: () => void
}

const DeleteRestoreTactoItem = ({
  title,
  message,
  isDeleteMode,
  confirmCallback,
  closeModal,
}: Props) => {
  return (
    <div className="delete-restore-item-modal" aria-label="delete-restore-item-modal">
      <div className="title">{title}</div>
      <div className="message">
        {isDeleteMode && <div className="alert-icon" />}
        <div>{message}</div>
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name={isDeleteMode ? '삭제' : '복원'} onClick={confirmCallback} />
      </div>
    </div>
  )
}

export default DeleteRestoreTactoItem
