import { ReactNode } from 'react'
import TopicTreeNode from '../domain/TopicTreeNode'
import { searchSelectedTopic } from '../util/topic-tree'

type Props = {
  rootNode?: TopicTreeNode | null
  changeRootNode?: (rootNode: TopicTreeNode) => void
  selectedId: string
  onSelectTopic: (id: string) => void
  openCollectModal?: (topicTreeNode: TopicTreeNode) => void
  draggable?: boolean
}

function drawTree(
  treeNodeMap: { [key in string]: TopicTreeNode },
  selectedId: string,
  onSelectTopic: (id: string) => void,
  openCollectModal?: (topicTreeNode: TopicTreeNode) => void,
  draggable?: boolean,
  rootNode?: TopicTreeNode,
  changeRootNode?: (rootNode: TopicTreeNode) => void
): ReactNode {
  return Object.values(treeNodeMap).map((value) => {
    return (
      <div key={value.id}>
        <div
          className={`tree-node-item children ${selectedId === value.id && 'on'}`}
          onClick={() => onSelectTopic(value.id)}
          draggable={true}
          data-destination={value.id}
          onDragStart={(e) => {
            e.dataTransfer.setData('draggingTargetId', value.id)
            e.dataTransfer.setData('draggingTargetParentId', value.parentId)
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault()
            const draggingTargetParentId = e.dataTransfer.getData('draggingTargetParentId')
            const draggingTargetId = e.dataTransfer.getData('draggingTargetId')
            const destinationContainer = e.currentTarget as HTMLElement
            const destinationId = destinationContainer.dataset.destination!
            if (!rootNode) {
              return
            }
            if (draggingTargetParentId !== destinationId) {
              const targetTopic = searchSelectedTopic(draggingTargetId, rootNode)
              const destinationTopic = searchSelectedTopic(destinationId, rootNode)
              if (!targetTopic || !destinationTopic) {
                return
              }
              if (searchSelectedTopic(destinationId, targetTopic)) {
                alert('자식 노드로 이동할 수 없습니다.')
                return
              }
              if (!destinationTopic?.children) {
                destinationTopic.children = {}
              }
              const size = Object.keys(destinationTopic.children).length
              destinationTopic.children = {
                ...destinationTopic.children,
                [draggingTargetId as string]: {
                  ...targetTopic,
                  parentId: destinationId,
                  seq: size + 1,
                },
              }
              const targetParentTopic = searchSelectedTopic(draggingTargetParentId, rootNode)
              if (targetParentTopic?.children) {
                delete targetParentTopic.children[draggingTargetId]
              }
            }
            if (rootNode && changeRootNode) {
              changeRootNode(rootNode)
            }
          }}
        >
          {selectedId === value.id && openCollectModal ? (
            <button
              className="icon collect"
              aria-label="collect-button"
              onClick={() => openCollectModal(value)}
            />
          ) : (
            <div className={`icon open-folder ${selectedId === value.id && 'on'}`} />
          )}
          <div className="topic-name">{value.name}</div>
          {draggable && <div className="icon drag-handle" />}
        </div>
        {value.children && (
          <ul className="children">
            {drawTree(
              value.children,
              selectedId,
              onSelectTopic,
              openCollectModal,
              draggable,
              rootNode,
              changeRootNode
            )}
          </ul>
        )}
      </div>
    )
  })
}

const TopicTreeSection = ({
  rootNode,
  changeRootNode,
  selectedId,
  onSelectTopic,
  openCollectModal,
  draggable,
}: Props) => {
  return (
    <>
      {rootNode?.children && (
        <div className="topic-tree-section" aria-label="topic-tree-section">
          {drawTree(
            rootNode.children,
            selectedId,
            onSelectTopic,
            openCollectModal,
            draggable,
            rootNode,
            changeRootNode
          )}
        </div>
      )}
    </>
  )
}

export default TopicTreeSection
