import TopicTreeNode from '../domain/TopicTreeNode'
import { RightSection } from './TopicTreeManagement'
import React, { useState } from 'react'
import { getTopicNodesInTopicMap } from '../repository/TactoProjectRepository'
import ReactDom from 'react-dom'

type Props = {
  onCreateTopicTreeList: (topicTreeList: TopicTreeNode[]) => void
  setRightSection: (rightSection: RightSection) => void
}

const CreateTopicByTopicMapSection = ({ onCreateTopicTreeList, setRightSection }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [topicTrees, setTopicTrees] = useState<TopicTreeNode[]>([])

  const fetchTopicsInTopicMap = () => {
    setIsLoading(true)
    getTopicNodesInTopicMap(keyword)
      .then((response) => {
        if (response.length === 0) {
          alert('토픽맵에 결과가 없습니다.')
          return
        }
        setTopicTrees(response)
      })
      .finally(() => setIsLoading(false))
  }

  const initializeTopicList = () => {
    setTopicTrees([])
    setKeyword('')
  }

  const onCreateTopicTreeListHandler = () => {
    onCreateTopicTreeList(topicTrees)
    setRightSection('')
  }

  return (
    <>
      {isLoading &&
        ReactDom.createPortal(
          <div className="spinner-wrap">
            <div id="dim" />
            <div className="spinner" />
          </div>,
          document.getElementById('modal')!
        )}
      <div aria-label="creation-topic-based-topic-map" className="creation-topic-based-topic-map">
        <div className="title">토픽맵 DB에서 검색하기</div>
        <div className="keyword-form">
          <div className="keyword-label">토픽맵 키워드</div>
          <div className="keyword-input">
            <input
              type="text"
              aria-label="search-keyword"
              value={keyword}
              disabled={topicTrees.length > 0}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
        </div>
        {topicTrees.length === 0 ? (
          <>
            <div className="option-description">
              검색 키워드를 입력하고, 토픽 만들기 버튼을 클릭해주세요.
            </div>
            <div className="buttons">
              <button className="cancel" onClick={() => setRightSection('')}>
                취소
              </button>
              <button className="confirm" onClick={fetchTopicsInTopicMap}>
                토픽 만들기
              </button>
            </div>
          </>
        ) : (
          <div className="suggestion-container">
            <div className="topic-tree">
              {topicTrees.map((topicTree) => (
                <div className="tree-node-item" key={topicTree.id}>
                  {topicTree.name}
                </div>
              ))}
            </div>
            <div className="buttons">
              <button className="cancel" onClick={initializeTopicList}>
                초기화
              </button>
              <button className="confirm" onClick={onCreateTopicTreeListHandler}>
                적용
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CreateTopicByTopicMapSection
