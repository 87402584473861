import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { usePageable } from '../../common/hooks/usePageable'
import Location from '../../common/component/Location'
import Pagination from '../../common/component/Pagination'
import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'
import NiceSelect from '../../common/component/NiceSelect'

import TopicTreeSection from '../component/TopicTreeSection'
import SuggestionSentence from '../component/SuggestionSentence'
import MaterialDetailsSection from '../component/MaterialDetailsSection'

import Sentence from '../domain/Sentence'
import MaterialDetail from '../domain/MaterialDetail'
import Material from '../domain/Material'
import TopicTreeNode from '../domain/TopicTreeNode'
import TactoProjectDetail, { initialTactoProjectDetail } from '../domain/TactoProjectDetail'
import simpleTactoArticle, { initialSimpleTactoArticle } from '../domain/SimpleTactoArticle'

import { debounce } from '../../common/utils/debounce'
import { updatedDate } from '../util/time-formatter'
import { searchSelectedTopic } from '../util/topic-tree'

import { getProject } from '../repository/TactoProjectRepository'
import {
  getMaterialDetail,
  getMaterials,
  updateMaterialDetail,
} from '../repository/MaterialsRepository'
import { getTactoArticle, saveTactoArticle } from '../repository/TactoArticleRepository'
import { searchSentences } from '../repository/SentenceRepository'

const TactoArticle = () => {
  const { projectId, articleId } = useParams()
  const navigate = useNavigate()
  const [isRealTimeSuggestion, setIsRealTimeSuggestion] = useState<boolean>(true)
  const [keyword, setKeyword] = useState<string>('')
  const [materials, setMaterials] = useState<Material[]>([])
  const [materialDetail, setMaterialDetail] = useState<MaterialDetail | null>(null)
  const [projectDetail, setProjectDetail] = useState<TactoProjectDetail>(initialTactoProjectDetail)
  const [selectedTopic, setSelectedTopic] = useState<TopicTreeNode | null>(null)
  const [tactoArticle, setTactoArticle] = useState<simpleTactoArticle>(initialSimpleTactoArticle)

  const [searchText, setSearchText] = useState<string>('')
  const [recommendSentences, setRecommendedSentences] = useState<Sentence[]>([])
  const [category, setCategory] = useState<number | null>(null)
  const [type, setType] = useState<string | null>(null)
  const [year, setYear] = useState<string | null>(null)
  const [language, setLanguage] = useState<string>('ALL')
  const [realTimeRecommendedSentence, setRealTimeRecommendedSentence] = useState<Sentence | null>(
    null
  )
  const [selectedSentence, setSelectedSentence] = useState<string>('')

  const currentWidth = useRef<number>(400)
  const startWidth = useRef<number>(0)
  const leftSectionRef = useRef<HTMLDivElement>(null)
  const { pageInfo, setupPage } = usePageable()

  const areaOptions = useMemo(
    () =>
      new Map([
        ['null', '전체'],
        ['2', '인문과학'],
        ['32', '예술체육'],
        ['47', '사회과학'],
        ['71', '자연과학'],
        ['84', '공학'],
        ['111', '의약학'],
        ['155', '농수해양'],
        ['166', '복합학'],
        ['172', '교육'],
      ]),
    []
  )

  const stepOptions = useMemo(
    () =>
      new Map([
        ['null', '전체'],
        ['배경', '배경'],
        ['목적', '목적'],
        ['방법', '방법'],
        ['결과', '결과'],
        ['결론', '결론'],
      ]),
    []
  )

  const periodOptions = useMemo(
    () =>
      new Map([
        ['null', '전체'],
        ['3', '최근 3년'],
        ['5', '최근 5년'],
        ['10', '최근 10년'],
      ]),
    []
  )

  const languageOptions = useMemo(
    () =>
      new Map([
        ['ALL', '전체'],
        ['KOREAN', '한국어'],
        ['ENGLISH', '영어'],
      ]),
    []
  )

  const resize = useCallback((evt: MouseEvent) => {
    const dx = evt.screenX - startWidth.current
    startWidth.current = evt.screenX
    const width = currentWidth.current + dx
    if (width <= 820 && width >= 340 && leftSectionRef.current) {
      currentWidth.current = width
      leftSectionRef.current.style.width = `${width}px`
      leftSectionRef.current.style.minWidth = `${width}px`
    }
  }, [])

  const draggableResize = useCallback(
    (e: ReactMouseEvent) => {
      startWidth.current = e.screenX
      const releaseResize = () => {
        document.removeEventListener('mousemove', resize)
        document.removeEventListener('mouseup', releaseResize)
      }
      document.addEventListener('mousemove', resize)
      document.addEventListener('mouseup', releaseResize)
    },
    [resize]
  )

  useEffect(() => {
    if (projectId) {
      getProject(+projectId).then((response) => {
        setProjectDetail(response)
        if (response.rootNode?.children) {
          const firstChild = Object.values(response.rootNode.children)[0]
          setSelectedTopic(firstChild)
        }
      })
    }
  }, [projectId])

  const moveToPage = useCallback(
    (currentPage: number) => {
      if (selectedTopic) {
        getMaterials(selectedTopic.id, keyword, false, { page: currentPage, size: 10 }).then(
          (response) => {
            setupPage(response, currentPage)
            setMaterials(response.content)
          }
        )
      }
    },
    [selectedTopic, keyword, setupPage]
  )

  const onSelectTopic = useCallback(
    (id: string) => {
      if (projectDetail.rootNode) {
        const foundChildTopic = searchSelectedTopic(id, projectDetail.rootNode)
        setSelectedTopic(foundChildTopic)
      }
    },
    [projectDetail.rootNode]
  )

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [selectedTopic])

  useEffect(() => {
    if (projectDetail.id && articleId) {
      getTactoArticle(projectDetail.id, +articleId).then((response) => setTactoArticle(response))
    }
  }, [articleId, projectDetail])

  const onSearchHandler = useCallback(() => moveToPage(0), [moveToPage])

  const onSearchSentencesHandler = useCallback(
    () =>
      searchSentences({
        searchText,
        categoryId: category,
        type: type,
        year: year,
        size: 7,
        language,
      }).then((sentences) => setRecommendedSentences(sentences)),
    [searchText, category, type, year, language]
  )

  const fetchMaterialDetail = (materialId: number) => {
    getMaterialDetail(materialId).then((response) => setMaterialDetail(response))
  }

  const onSaveHandler = useCallback(() => {
    saveTactoArticle(projectDetail.id, tactoArticle)
      .then((id) =>
        navigate(`/knotes/projects/${projectDetail.id}/articles/${id}`, { replace: true })
      )
      .finally(() => alert('저장되었습니다.'))
  }, [navigate, projectDetail, tactoArticle])

  const onCompleteHandler = useCallback(() => {
    saveTactoArticle(projectDetail.id, { ...tactoArticle, isCompleted: true })
      .then((id) => {
        navigate(`/knotes/projects/${projectDetail.id}/articles/${id}`, { replace: true })
      })
      .finally(() => alert('완료되었습니다.'))
  }, [navigate, projectDetail, tactoArticle])

  const searchSentenceRealTime = useCallback((content: string) => {
    const sentencesInContent = content.split('.')
    if (sentencesInContent.length > 1) {
      searchSentences({
        searchText: sentencesInContent[sentencesInContent.length - 2],
        categoryId: null,
        type: null,
        year: null,
        size: 1,
        language: 'ALL',
      }).then((sentences) => {
        if (sentences.length > 0) {
          setRealTimeRecommendedSentence(sentences[0])
        } else {
          setRealTimeRecommendedSentence(null)
        }
      })
    }
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(searchSentenceRealTime, 2000), [
    searchSentenceRealTime,
  ])

  return (
    <main>
      <div className="container">
        <Location
          paths={['워크스페이스', projectDetail.name]}
          navigates={{ 워크스페이스: () => navigate('/knotes', { replace: true }) }}
        />
        <div className="top-section">
          <div className="back" aria-label="back-button" onClick={() => navigate(-1)} />
          <div className="project-info">
            <h1 className="head">{projectDetail.name}</h1>
            <div className="description">{projectDetail.description}</div>
          </div>
        </div>
        <div className="middle-section">
          <h2>아티클 모드</h2>
          <div onClick={() => navigate(-1)} className="list-button">
            <div className="list-icon" />
            <div>목록으로</div>
          </div>
        </div>
        <div className="content-wrap">
          <div className="left-section" ref={leftSectionRef}>
            <div className="side-topic-tree" aria-label="topic-tree">
              <div className="topic-tree-name">
                <div className="icon tree" />
                <div>{projectDetail.rootNode?.name || projectDetail.name}</div>
              </div>
              <TopicTreeSection
                rootNode={projectDetail?.rootNode}
                selectedId={selectedTopic?.id ?? ''}
                onSelectTopic={onSelectTopic}
              />
            </div>
            <div className="materials" aria-label="materials">
              {materialDetail ? (
                <div aria-label="material-detail" className="material-detail">
                  <div className="title">{materialDetail.title}</div>
                  <MaterialDetailsSection
                    materialDetail={materialDetail}
                    onChangeMaterialDetail={(key, value) =>
                      setMaterialDetail({ ...materialDetail, [key]: value })
                    }
                  />
                  <div className="buttons">
                    <CancelButton name="취소" onClick={() => setMaterialDetail(null)} />
                    <ConfirmButton
                      name="저장"
                      onClick={() => {
                        updateMaterialDetail(materialDetail).then(() => {
                          moveToPage(0)
                          setMaterialDetail(null)
                        })
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="material-list" aria-label="material-list">
                  <div className="search">
                    <input
                      type="text"
                      placeholder="검색어를 입력해주세요"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <button aria-label="search" onClick={onSearchHandler} />
                  </div>
                  <div className="project-item-list">
                    {materials.map((material) => (
                      <div key={material.id} className="project-item-wrap">
                        <div className="material" onClick={() => fetchMaterialDetail(material.id)}>
                          <div className="title">{material.title}</div>
                          <div className="content">
                            <div className="left-container">
                              <div>{material.publishYear}</div>
                              <div className="divider" />
                              <div>{material.publisher}</div>
                              <div className="divider" />
                              <div>{material.authors}</div>
                            </div>
                          </div>
                          <div className="attachment-memo-container">
                            <button className={material.attachmentId ? 'existed' : ''}>
                              <div className="icon attachment" />
                              첨부
                            </button>
                            <button className={material.memoId ? 'existed' : ''}>
                              <div className="icon memo" />
                              메모
                            </button>
                            <div className="updated-at">{updatedDate(material.updatedAt)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Pagination moveToPage={moveToPage} pageInfo={pageInfo} />
                </div>
              )}
            </div>
          </div>
          <div className="divider-line">
            <div className="divider-thumb" onMouseDown={draggableResize} />
          </div>
          <div className="right-section">
            <div aria-label="text-editor" className="text-editor">
              <div className="top-section">
                <div className="section-title">아티클 작성</div>
                <label className="suggestion-toggle">
                  <span>실시간 문장 추천</span>
                  <input
                    role="switch"
                    type="checkbox"
                    aria-label="real-time-suggestion"
                    checked={isRealTimeSuggestion}
                    onChange={(e) => setIsRealTimeSuggestion(e.target.checked)}
                  />
                </label>
              </div>
              <div className="content">
                <div className="label">제목</div>
                <input
                  type="text"
                  aria-label="tacto-article-title"
                  value={tactoArticle.title}
                  onChange={(e) => setTactoArticle({ ...tactoArticle, title: e.target.value })}
                />
              </div>
              <div className="content">
                <div className="label">
                  <div>내용</div>
                  <div>{tactoArticle.content.length} 자</div>
                </div>
                <textarea
                  aria-label="tacto-article-content"
                  value={tactoArticle.content}
                  onChange={(e) => {
                    setTactoArticle({ ...tactoArticle, content: e.target.value })
                    if (isRealTimeSuggestion) {
                      debouncedSearch(e.target.value)
                    }
                  }}
                />
                {realTimeRecommendedSentence && (
                  <SuggestionSentence
                    ariaLabel="real-time-suggestion-sentence"
                    sentence={realTimeRecommendedSentence.sentence}
                    categoryId={0}
                  />
                )}
              </div>
              <div className="buttons">
                <button className="save" onClick={onSaveHandler}>
                  저장
                </button>
                <button className="done" onClick={onCompleteHandler}>
                  완료
                </button>
              </div>
            </div>
            <div className="suggestions" aria-label="suggestions">
              <div className="top-section">
                <div className="section-title">문장 추천</div>
              </div>
              <div className="select-box-section">
                <div className="select-box-wrap">
                  <div className="label">분야</div>
                  <NiceSelect
                    ariaLabel="areas"
                    options={areaOptions}
                    onSelectCallback={(key) => {
                      if (key === 'null') {
                        setCategory(null)
                      } else {
                        setCategory(+key)
                      }
                    }}
                    selectedValue={category === null ? '전체' : areaOptions.get(`${category}`)}
                  />
                </div>
                <div className="select-box-wrap">
                  <div className="label">단계</div>
                  <NiceSelect
                    ariaLabel="steps"
                    options={stepOptions}
                    onSelectCallback={(key) => setType(key === 'null' ? null : key)}
                    selectedValue={type === null ? '전체' : stepOptions.get(`${type}`)}
                  />
                </div>
                <div className="select-box-wrap">
                  <div className="label">기간</div>
                  <NiceSelect
                    ariaLabel="periods"
                    options={periodOptions}
                    onSelectCallback={(key) => setYear(key === 'null' ? null : key)}
                    selectedValue={year === null ? '전체' : periodOptions.get(`${year}`)}
                  />
                </div>
                <div className="select-box-wrap">
                  <div className="label">언어</div>
                  <NiceSelect
                    ariaLabel="languages"
                    options={languageOptions}
                    onSelectCallback={(key) => setLanguage(key)}
                    selectedValue={languageOptions.get(language)}
                  />
                </div>
              </div>
              <div className="search">
                <input
                  aria-label="search-text"
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <button aria-label="search-suggestions" onClick={onSearchSentencesHandler} />
              </div>
              <div aria-label="actions-section">
                {selectedSentence && (
                  <SuggestionSentence
                    ariaLabel="suggestion-sentence"
                    sentence={selectedSentence}
                    categoryId={category}
                  />
                )}
              </div>
              <div className="suggestion-paragraphs">
                {recommendSentences.map((item, index) => (
                  <div
                    className={`suggestion-item ${item.sentence === selectedSentence && 'on'}`}
                    key={index}
                    onClick={() => setSelectedSentence(item.sentence)}
                  >
                    <div>{item.sentence}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default TactoArticle
