import React from 'react'
import { useNavigate } from 'react-router-dom'

import ModalWindow from '../../common/component/ModalWindow'
import DeleteRestoreTactoItem from './DeleteRestoreTactoItem'

import WorkspaceTactoArticle from '../domain/WorkspaceTactoArticle'

import { updatedDate } from '../util/time-formatter'
import { deleteTactoArticle, restoreTactoArticle } from '../repository/TactoWorkspaceRepository'

type Props = {
  tactoArticles: WorkspaceTactoArticle[]
  isInTrash: boolean
  refreshItems: () => void
}

const TactoArticleList = ({ tactoArticles, isInTrash, refreshItems }: Props) => {
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = React.useState<WorkspaceTactoArticle | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false)
  const [restoreModalOpen, setRestoreModalOpen] = React.useState<boolean>(false)

  return (
    <>
      <ModalWindow isOpen={restoreModalOpen} closeModal={() => setRestoreModalOpen(false)}>
        <DeleteRestoreTactoItem
          title="아티클 복원"
          message="복원하시겠습니까?"
          isDeleteMode={false}
          confirmCallback={() => {
            if (selectedItem) {
              restoreTactoArticle(selectedItem.id).then(refreshItems)
            }
          }}
          closeModal={() => setRestoreModalOpen(false)}
        />
      </ModalWindow>
      <ModalWindow isOpen={deleteModalOpen} closeModal={() => setDeleteModalOpen(false)}>
        <DeleteRestoreTactoItem
          title="아티클 삭제"
          message="삭제하시겠습니까?"
          isDeleteMode={true}
          confirmCallback={() => {
            if (selectedItem) {
              deleteTactoArticle(selectedItem.id).then(refreshItems)
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
        />
      </ModalWindow>
      <div className="workspace-items" aria-label="tacto-article-list">
        {tactoArticles.map((item, index) => (
          <div className="workspace-item-wrap" key={`${item.id}-${index}`}>
            <div
              className="workspace-item"
              onClick={() => {
                if (!isInTrash) {
                  navigate(`/knotes/projects/${item.projectId}/articles/${item.id}`)
                }
              }}
            >
              <div className="project-name">{item.projectName}</div>
              <div className="name">{item.title}</div>
              <div className="description">{item.content}</div>
              <div className="progress-container">
                <div className={`progress-badge ${item.isCompleted ? 'completed' : ''}`}>
                  <span>{item.contentSize} 자</span>
                  <span className="dot" />
                  <span>{item.isCompleted ? '완료' : '작성중'}</span>
                </div>
                <div className="updated-at">
                  {updatedDate(item.updatedAt, isInTrash ? '삭제' : '수정')}
                </div>
              </div>
            </div>
            {isInTrash && (
              <div className="trash-buttons">
                <button
                  className="restore"
                  onClick={(e) => {
                    e.preventDefault()
                    setRestoreModalOpen(true)
                    setSelectedItem(item)
                  }}
                >
                  복원
                </button>
                <button
                  className="delete"
                  onClick={(e) => {
                    e.preventDefault()
                    setDeleteModalOpen(true)
                    setSelectedItem(item)
                  }}
                  aria-label="delete"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default TactoArticleList
