import RecentWorkspaceItems from '../domain/RecentWorkspaceItems'
import axios from 'axios'
import Page from '../../common/domain/Page'
import WorkspaceTopicTree from '../domain/WorkspaceTopicTree'
import PageRequest from '../../common/domain/PageRequest'
import WorkspaceTactoProject from '../domain/WorkspaceTactoProject'
import WorkspaceMemo from '../domain/WorkspaceMemo'
import WorkspaceTactoArticle from '../domain/WorkspaceTactoArticle'
import WorkspaceMaterial from '../domain/WorkspaceMaterial'

export const getRecentWorkspace = async (): Promise<RecentWorkspaceItems> => {
  const { data } = await axios.get('/app/api/workspace/recent-items')
  return data
}

export const getProjects = async (
  keyword: string,
  inTrash: boolean,
  pageRequest: PageRequest
): Promise<Page<WorkspaceTactoProject>> => {
  const { data } = await axios.get('/app/api/workspace/projects', {
    params: { ...pageRequest, keyword, inTrash },
  })
  return data
}

export const getTopicTrees = async (
  keyword: string,
  inTrash: boolean,
  pageRequest: PageRequest
): Promise<Page<WorkspaceTopicTree>> => {
  const { data } = await axios.get('/app/api/workspace/topic-trees', {
    params: { ...pageRequest, keyword, inTrash },
  })
  return data
}

export const getMemos = async (
  keyword: string,
  pageRequest: PageRequest
): Promise<Page<WorkspaceMemo>> => {
  const { data } = await axios.get('/app/api/workspace/memos', {
    params: { ...pageRequest, keyword },
  })
  return data
}

export const getTactoArticles = async (
  keyword: string,
  inTrash: boolean,
  pageRequest: PageRequest
): Promise<Page<WorkspaceTactoArticle>> => {
  const { data } = await axios.get('/app/api/workspace/tacto-articles', {
    params: { ...pageRequest, keyword, inTrash },
  })
  return data
}

export const getMaterials = async (
  keyword: string,
  inTrash: boolean,
  pageRequest: PageRequest
): Promise<Page<WorkspaceMaterial>> => {
  const { data } = await axios.get('/app/api/workspace/materials', {
    params: { ...pageRequest, keyword, inTrash },
  })
  return data
}

export const restoreProject = async (id: number): Promise<void> => {
  await axios.put(`/app/api/workspace/projects/${id}`)
}

export const deleteProject = async (id: number): Promise<void> => {
  await axios.delete(`/app/api/workspace/projects/${id}`)
}

export const restoreTopicTree = async (id: string): Promise<void> => {
  await axios.put(`/app/api/workspace/topic-trees/${id}`)
}

export const deleteTopicTree = async (id: string): Promise<void> => {
  await axios.delete(`/app/api/workspace/topic-trees/${id}`)
}

export const restoreTactoArticle = async (id: number): Promise<void> => {
  await axios.put(`/app/api/workspace/tacto-articles/${id}`)
}

export const deleteTactoArticle = async (id: number): Promise<void> => {
  await axios.delete(`/app/api/workspace/tacto-articles/${id}`)
}

export const restoreMaterial = async (id: number): Promise<void> => {
  await axios.put(`/app/api/workspace/materials/${id}`)
}

export const deleteMaterial = async (id: number): Promise<void> => {
  await axios.delete(`/app/api/workspace/materials/${id}`)
}
